import React from "react";
import AdventureCard from "./adventure_card"; 
import l_img1 from '../public/bg_image_landing.jpeg';
import l_img2 from '../public/bg_image_landing_2.jpeg';
import l_img3 from '../public/bg_image_landing_3.jpeg';
import l_img4 from '../public/bg_image_landing_4.jpeg';
import l_img5 from '../public/bg_image_landing_5.jpeg';
import about_us_img from '../public/image_1.jpeg';
import adventure1 from '../public/adventure_1.jpeg';
import adventure2 from '../public/adventure_2.jpg';
import adventure3 from '../public/adventure_3.jpeg';
class App extends React.Component {
    componentDidMount() {
        this.setState({
            img_index: Math.floor(Math.random() * 5),
        });
    }

    constructor(props) {
        super(props);
        this.state = {
            image_map: {
                0: ["34° N, 77° E", "Markha Valley", l_img1],
                1: ["34.1526° N, 77.5771° E", "Leh", l_img2],
                2: ["33.7595° N, 78.6674° E", "Pangong Tso", l_img3],
                3: ["33.7595° N, 78.6674° E", "Pangong Tso", l_img4],
                4: ["34.1526° N, 77.5771° E", "Leh", l_img5],
            },
            img_index: 0,
            adventures_map: [
                {
                    image: adventure3,
                    image_name: "Nubra Valley",
                    trip_name: "Leh to Tso Moriri",
                    dates: "12th - 13th January",
                    itinerary: "Leh - 1 night\nNubra (via Khardungla) - 2 night\nPangong - 1 night\nTso Moriri - 1 night".split(/\r?\n/),
                    duration: "7 nights 8 days",
                    cost: "₹42,000"
                },
                {
                    image: adventure1,
                    image_name: "Kargil",
                    trip_name: "Srinagar to Leh",
                    dates: "12th - 13th January",
                    itinerary: "Srinagar - 1 night\nKargil - 1 night\nLeh - 2 night\nNubra (via Khardungla) - 2 night\nPangong - 1 night\nTso Moriri - 1 night\nLeh - 1 night".split(/\r?\n/),
                    duration: "9 nights 10 days",
                    cost: "₹70,000"
                },
                {
                    image: adventure2,
                    image_name: "Barlacha Pass",
                    trip_name: "Srinagar to Manali",
                    dates: "12th - 13th January",
                    itinerary: "Srinagar - 1 night\nKargil - 1 night\nLeh - 1 night\nNubra - 2 night\nPangong - 1 night\nTso Moriri - 1 night\nJispa - 1 night\nManali - 1 night".split(/\r?\n/),
                    duration: "10 nights 11 days",
                    cost: "₹90,000"
                },
            ],
        };
    }

    scroll_to_about_us() {
        var e = document.getElementById("about_us").scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'center'
        });
    }

    scroll_to_adventures() {
        var e = document.getElementById("adventures").scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'center'
        });
    }
    
    scroll_to_contact_us() {
        var e = document.getElementById("contact_us").scrollIntoView({
            block: 'start',
            behavior: 'smooth',
            inline: 'center'
        });
    }

    redirect_to_whatsapp() {
        window.open("https://wa.link/5ml3c0", "_blank");
    }

    render() {
        return (
            <div>
                <div id="landing" className="relative">
                    <p className="absolute font-fetance text-7xl font-light left-10 top-10">ladakh insight holidays</p>
                    <img src={this.state.image_map[this.state.img_index][2]} alt={this.state.image_map[this.state.img_index][1]} className="w-screen h-screen object-cover" />
                    <p className="absolute left-16 bottom-36 font-light text-2xl text-white">{this.state.image_map[this.state.img_index][0]}</p>
                    <p className="absolute left-16 bottom-20 font-normal text-2xl text-white">{this.state.image_map[this.state.img_index][1]}</p>
                    <div className="absolute bottom-6 w-screen flex justify-center">
                        <button onClick={this.scroll_to_about_us}>
                            <svg width="32" height="18" viewBox="0 0 32 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 17.3125C15.7951 17.315 15.5919 17.2746 15.4035 17.1939C15.2152 17.1131 15.0458 16.9939 14.9063 16.8437L0.84375 2.78125C0.21875 2.15625 0.21875 1.1875 0.84375 0.5625C1.46875 -0.0625 2.4375 -0.0625 3.0625 0.5625L16.0313 13.5312L28.9687 0.59375C29.5937 -0.03125 30.5625 -0.03125 31.1875 0.59375C31.8125 1.21875 31.8125 2.1875 31.1875 2.8125L17.125 16.875C16.8125 17.1875 16.4063 17.3438 16.0313 17.3438L16 17.3125Z" fill="#D5D5D5"/>
                            </svg>
                        </button>
                    </div>
                </div>
                <div id="about_us" className="relative flex">
                    <svg className="absolute left-0 bottom-36 md:top-96" width="283" height="300" viewBox="0 0 283 600" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M283 100.922C283 150.955 246.591 192.484 198.82 200.461C246.591 208.438 283 249.968 283 300C283 350.032 246.591 391.562 198.82 399.539C246.591 407.516 283 449.046 283 499.078C283 554.815 237.815 600 182.078 600C132.046 600 90.5161 563.591 82.5392 515.82C74.5622 563.591 33.0323 600 -17 600C-67.0323 600 -108.562 563.591 -116.539 515.82C-124.516 563.591 -166.045 600 -216.078 600C-271.816 600 -317 554.815 -317 499.078C-317 449.046 -280.59 407.516 -232.822 399.539C-280.59 391.562 -317 350.032 -317 300C-317 249.968 -280.59 208.438 -232.822 200.461C-280.59 192.484 -317 150.955 -317 100.922C-317 45.1842 -271.816 0 -216.078 0C-166.045 0 -124.516 36.4095 -116.539 84.1784C-108.562 36.4095 -67.0323 0 -17 0C33.0323 0 74.5622 36.4095 82.5392 84.1784C90.5161 36.4095 132.046 0 182.078 0C237.815 0 283 45.1842 283 100.922ZM-116.539 117.665C-123.606 159.988 -157.012 193.394 -199.335 200.461C-157.012 207.528 -123.606 240.935 -116.539 283.258C-109.472 240.935 -76.0654 207.528 -33.7419 200.461C-76.0654 193.394 -109.472 159.988 -116.539 117.665ZM-0.258087 200.461C42.0654 193.394 75.4719 159.988 82.5392 117.665C89.6064 159.988 123.013 193.394 165.336 200.461C123.013 207.528 89.6064 240.935 82.5392 283.258C75.4719 240.935 42.0654 207.528 -0.258087 200.461ZM82.5392 482.336C89.6064 440.013 123.013 406.606 165.336 399.539C123.013 392.472 89.6064 359.065 82.5392 316.742C75.4719 359.065 42.0654 392.472 -0.258087 399.539C42.0654 406.606 75.4719 440.013 82.5392 482.336ZM-116.539 316.742C-109.472 359.065 -76.0654 392.472 -33.7419 399.539C-76.0654 406.606 -109.472 440.013 -116.539 482.336C-123.606 440.013 -157.012 406.606 -199.335 399.539C-157.012 392.472 -123.606 359.065 -116.539 316.742Z" fill="#0075FF"/>
                    </svg>
                    <div className="z-10 px-10 py-5">
                        <p className="pt-10 text-2xl md:text-4xl underline font-medium">Your Ladakh Connection</p><br/><br/>
                        <img src={about_us_img} alt={"image"} width={400} className="float-left md:float-right py-3" />
                        <p className="text-xl md:text-2xl">Ladakh, an ethereal realm in northern India, is a place of unparalleled beauty. Its rugged landscapes, crowned by the mighty Himalayas, offer stunning contrasts. Pristine lakes mirror the azure skies, while ancient monasteries perch on dramatic cliffs. The region's unique blend of Tibetan and Indian cultures, vibrant festivals, and serene charm make Ladakh a paradise for both nature lovers and soul seekers. In Ladakh, nature's beauty and cultural richness converge, leaving an indelible mark on all who venture into this breathtaking land.</p><br/><br/><br/>
                        <p className="text-xl md:text-2xl">With insight holidays, you'll not only witness the awe-inspiring landscapes and rich cultural tapestry of Ladakh but also forge lasting connections with the people and places that make this region so unique. Embark on a journey of a lifetime with us, and let Ladakh's unparalleled beauty leave an indelible mark on your heart and soul.</p>
                    </div>
                    <hr className="absolute -bottom-16 left-1/4 border-custom-grey" width="50%" />
                </div>
                <div id="adventures" className="relative top-32">
                    <p className="text-2xl md:text-4xl underline font-medium justify-center absolute flex w-full">Adventures</p><br/>
                    <svg className="absolute -left-5 top-96" width="382" height="427" viewBox="0 0 382 627" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M103.638 61.7714C154.626 124.736 146.867 204.924 132.287 285.101C214.316 287.615 293.96 296.799 344.779 359.555C401.834 430.012 391.236 533.494 321.162 590.24C251.087 646.985 147.663 635.836 90.6079 565.379C39.6028 502.393 47.1598 420.115 61.011 342.819C-17.4106 340.281 -99.5449 330.56 -150.533 267.595C-207.591 197.133 -196.989 93.6571 -126.915 36.9117C-56.8398 -19.8337 46.6032 -8.66097 103.638 61.7714ZM72.1836 283.725C85.7546 210.856 93.9988 144.361 57.4241 99.1949C20.9587 54.1638 -44.9555 46.8903 -89.5524 83.0041C-134.125 119.098 -140.737 185.102 -104.271 230.133C-67.1782 275.985 -0.649143 281.893 72.1836 283.725ZM136.847 527.935C173.309 572.962 239.248 580.225 283.822 544.129C328.419 508.015 335.002 442.026 298.54 396.999C261.779 351.602 194.601 345.992 121.092 344.213C107.822 415.844 99.7502 482.125 136.847 527.935Z" fill="#FFA000"/>
                    </svg>
                    <div className="relative bg-custom-orange_medium h-40rem w-90/100 left-5/100 top-16 overflow-x-hidden overflow-y-scroll">
                        {this.state.adventures_map.map((i, index) => {
                            if(index < this.state.adventures_map.length-1)
                                return (
                                    <div key={index}>
                                        <AdventureCard redirect_to_whatsapp={this.redirect_to_whatsapp} image={i.image} image_name={i.image_name} trip_name={i.trip_name} itinerary={i.itinerary} dates={i.dates} duration={i.duration} cost={i.cost} key={index} />
                                        <hr className="relative -bottom-2 md:bottom-3 left-5/100 border-custom-grey" width="90%" />
                                    </div>)
                            else
                                return (
                                    <div key={index}>
                                        <AdventureCard redirect_to_whatsapp={this.redirect_to_whatsapp} image={i.image} image_name={i.image_name} trip_name={i.trip_name} itinerary={i.itinerary} dates={i.dates} duration={i.duration} cost={i.cost} key={index} />
                                    </div>)
                        })}
                    </div>
                    <hr className="absolute -bottom-32 left-1/4 border-custom-grey" width="50%" />
                </div>
                <div id="contact_us" className="relative top-72">
                    <div className="flex relative left-10 w-max text-2xl md:text-4xl">
                        <p className="relative underline font-medium">Contact us</p>
                        <p className="relative font-medium">:</p>
                    </div>
                    <div className="relative top-10 pl-10 w-full text-xl md:text-2xl">
                        <p className="font-bold">Skarma Galak</p>
                        <p>Ladakh Insight Holidays,</p>
                        <p>Skurbuchan village, Leh.</p>
                        <p>Ladakh - 194101.</p>
                        <p>+91 96229 64354</p><br/>
                        <p>ladakhinsightholidays@gmail.com</p><br/>
                        <div className="flex absolute -bottom-5">
                            <button>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.10665 12.5166C6.49333 9.75532 8.6206 7.4341 11.2507 5.81237C13.8808 4.19063 16.9101 3.33228 20 3.33325C24.4916 3.33325 28.265 4.98492 31.15 7.67492L26.3716 12.4549C24.6433 10.8033 22.4466 9.96159 20 9.96159C15.6583 9.96159 11.9833 12.8949 10.675 16.8333C10.3416 17.8333 10.1516 18.8999 10.1516 19.9999C10.1516 21.0999 10.3416 22.1666 10.675 23.1666C11.985 27.1066 15.6583 30.0383 20 30.0383C22.2416 30.0383 24.15 29.4466 25.6433 28.4466C26.509 27.8766 27.2502 27.137 27.822 26.2724C28.3938 25.4079 28.7843 24.4363 28.97 23.4166H20V16.9699H35.6966C35.8933 18.0599 36 19.1966 36 20.3783C36 25.4549 34.1833 29.7283 31.03 32.6283C28.2733 35.1749 24.5 36.6666 20 36.6666C17.811 36.6675 15.6434 36.237 13.6209 35.3997C11.5984 34.5624 9.76073 33.3348 8.21291 31.787C6.6651 30.2392 5.43747 28.4015 4.6002 26.379C3.76294 24.3565 3.33244 22.1889 3.33331 19.9999C3.33331 17.3099 3.97665 14.7666 5.10665 12.5166Z" fill="black"/>
                                </svg>
                            </button>
                            <button className="relative left-10" onClick={this.redirect_to_whatsapp}>
                                <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31.75 8.18335C30.222 6.63989 28.402 5.41613 26.3961 4.58344C24.3902 3.75074 22.2385 3.32579 20.0667 3.33335C10.9667 3.33335 3.55002 10.75 3.55002 19.85C3.55002 22.7667 4.31669 25.6 5.75002 28.1L3.41669 36.6667L12.1667 34.3667C14.5834 35.6833 17.3 36.3834 20.0667 36.3834C29.1667 36.3834 36.5834 28.9667 36.5834 19.8667C36.5834 15.45 34.8667 11.3 31.75 8.18335ZM20.0667 33.5833C17.6 33.5833 15.1834 32.9167 13.0667 31.6667L12.5667 31.3667L7.36669 32.7333L8.75002 27.6667L8.41669 27.15C7.04626 24.9616 6.31859 22.4321 6.31669 19.85C6.31669 12.2833 12.4834 6.11668 20.05 6.11668C23.7167 6.11668 27.1667 7.55002 29.75 10.15C31.0292 11.4233 32.0429 12.9378 32.7323 14.6057C33.4218 16.2737 33.7734 18.0619 33.7667 19.8667C33.8 27.4333 27.6334 33.5833 20.0667 33.5833ZM27.6 23.3167C27.1834 23.1167 25.15 22.1167 24.7834 21.9667C24.4 21.8334 24.1334 21.7667 23.85 22.1667C23.5667 22.5834 22.7834 23.5167 22.55 23.7833C22.3167 24.0667 22.0667 24.1 21.65 23.8834C21.2334 23.6833 19.9 23.2334 18.3334 21.8334C17.1 20.7334 16.2834 19.3834 16.0334 18.9667C15.8 18.55 16 18.3334 16.2167 18.1167C16.4 17.9333 16.6334 17.6333 16.8334 17.4C17.0334 17.1667 17.1167 16.9833 17.25 16.7167C17.3834 16.4333 17.3167 16.2 17.2167 16C17.1167 15.8 16.2834 13.7667 15.95 12.9334C15.6167 12.1334 15.2667 12.2334 15.0167 12.2167H14.2167C13.9334 12.2167 13.5 12.3167 13.1167 12.7334C12.75 13.15 11.6834 14.15 11.6834 16.1834C11.6834 18.2167 13.1667 20.1834 13.3667 20.45C13.5667 20.7334 16.2834 24.9 20.4167 26.6833C21.4 27.1167 22.1667 27.3667 22.7667 27.55C23.75 27.8667 24.65 27.8167 25.3667 27.7167C26.1667 27.6 27.8167 26.7167 28.15 25.75C28.5 24.7833 28.5 23.9667 28.3834 23.7833C28.2667 23.6 28.0167 23.5167 27.6 23.3167Z" fill="black"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <svg className="absolute right-0 top-36 hidden md:block" width="299" height="308" viewBox="0 0 299 408" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M111.684 9.57956C148.123 -9.79503 193.367 4.03799 212.742 40.476C232.116 76.9139 218.284 122.158 181.845 141.533C145.407 160.908 100.163 147.075 80.7882 110.637C61.4138 74.1989 75.2461 28.9542 111.684 9.57956ZM181.621 407.014C140.755 401.272 112.281 363.486 118.025 322.619C123.768 281.752 161.553 253.279 202.42 259.023C243.287 264.765 271.76 302.551 266.018 343.418C260.274 384.285 222.488 412.758 181.621 407.014ZM367.874 189.772C404.966 207.862 420.37 252.597 402.279 289.688C384.188 326.781 339.453 342.185 302.362 324.093C265.27 306.002 249.866 261.267 267.957 224.176C286.048 187.084 330.783 171.68 367.874 189.772ZM2.00819 233.778C-5.15778 193.136 21.9792 154.38 62.6209 147.214C103.262 140.048 142.019 167.186 149.185 207.827C156.351 248.468 129.213 287.224 88.5718 294.39C47.9302 301.556 9.17489 274.42 2.00819 233.778ZM253.18 45.8922C281.848 16.2056 329.153 15.3804 358.838 44.0475C388.525 72.716 389.35 120.02 360.683 149.706C332.015 179.393 284.711 180.218 255.025 151.551C225.339 122.884 224.513 75.578 253.18 45.8922Z" fill="#0075FF"/>
                    </svg>
                    <div className="absolute top-96 bg-custom-black w-screen h-80 text-custom-grey">
                        <div className="absolute hidden md:block left-10 top-10/100 h-4/5 border-l border-custom-grey"></div>
                        <div className="absolute text-lg md:text-2xl text-gray-200 left-10 md:left-24 top-10 md:top-14 space-y-5 md:space-y-14">
                            <button onClick={this.scroll_to_about_us}>About us</button><br/>
                            <button onClick={this.scroll_to_adventures}>Adventures</button><br/>
                            <button onClick={this.scroll_to_contact_us}>Contact us</button>
                        </div>
                        <div className="absolute hidden md:block left-2/3 top-10/100 h-4/5 border-l border-custom-grey"></div>
                        <div className="absolute md:block hidden text-2xl text-custom-grey pl-12 left-2/3 top-14 space-y-24">
                            <p>Ladakh awaits you....</p>
                            <div className="space-y-3 md:space-y-8">
                                <p className="text-base md:text-xl">Ladakh Insight Holidays</p>
                                <p className="text-sm md:text-lg">Site developed by <span className="font-bold">sagnik</span>.</p>
                            </div>
                        </div>
                        <div className="absolute block md:hidden right-10 top-10/100 h-4/5 border-l border-custom-grey"></div>
                        <div className="absolute bottom-10 left-10 md:hidden block text-lg text-custom-grey">
                            <div className="space-y-3">
                                <p className="text-base md:text-xl">Ladakh Insight Holidays</p>
                                <p className="text-sm md:text-lg">Site developed by <span className="font-bold">sagnik</span>.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );  
    }
}

export default App;