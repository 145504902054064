import React from "react";

class AdventureCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        console.log(this.props.itinerary);
        return (
            <div className="w-full h-max px-5 py-12 text-sm md:text-lg">
                <img className="object-cover ml-auto mr-auto" src={this.props.image} alt={this.props.image_name} title={this.props.image_name} />
                <p className="relative font-bold text-lg md:text-2xl left-3 top-2">{this.props.trip_name}</p>
                <div className="relative left-3 top-2 space-y-3">
                    <p>{this.props.duration}</p>
                    <div>
                        <p className="font-semibold">Itinerary:</p>
                        <ul className="ml-2">
                            {this.props.itinerary.map((i, index) => {
                                return <li key={index}>{i}</li>
                            })}
                        </ul>
                    </div>
                    <p>{this.props.cost}</p>
                </div>
                <div className="flex relative left-3 md:left-2/3 -bottom-3 md:bottom-8">
                    <p className="font-medium text-sm md:text-xl">Interested? Let's talk....</p>
                    <button className="relative -right-2 md:-right-4 bottom-1 md:bottom-0" onClick={this.props.redirect_to_whatsapp}>
                        <svg width="40" height="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M31.75 8.18335C30.222 6.63989 28.402 5.41613 26.3961 4.58344C24.3902 3.75074 22.2385 3.32579 20.0667 3.33335C10.9667 3.33335 3.55002 10.75 3.55002 19.85C3.55002 22.7667 4.31669 25.6 5.75002 28.1L3.41669 36.6667L12.1667 34.3667C14.5834 35.6833 17.3 36.3834 20.0667 36.3834C29.1667 36.3834 36.5834 28.9667 36.5834 19.8667C36.5834 15.45 34.8667 11.3 31.75 8.18335ZM20.0667 33.5833C17.6 33.5833 15.1834 32.9167 13.0667 31.6667L12.5667 31.3667L7.36669 32.7333L8.75002 27.6667L8.41669 27.15C7.04626 24.9616 6.31859 22.4321 6.31669 19.85C6.31669 12.2833 12.4834 6.11668 20.05 6.11668C23.7167 6.11668 27.1667 7.55002 29.75 10.15C31.0292 11.4233 32.0429 12.9378 32.7323 14.6057C33.4218 16.2737 33.7734 18.0619 33.7667 19.8667C33.8 27.4333 27.6334 33.5833 20.0667 33.5833ZM27.6 23.3167C27.1834 23.1167 25.15 22.1167 24.7834 21.9667C24.4 21.8334 24.1334 21.7667 23.85 22.1667C23.5667 22.5834 22.7834 23.5167 22.55 23.7833C22.3167 24.0667 22.0667 24.1 21.65 23.8834C21.2334 23.6833 19.9 23.2334 18.3334 21.8334C17.1 20.7334 16.2834 19.3834 16.0334 18.9667C15.8 18.55 16 18.3334 16.2167 18.1167C16.4 17.9333 16.6334 17.6333 16.8334 17.4C17.0334 17.1667 17.1167 16.9833 17.25 16.7167C17.3834 16.4333 17.3167 16.2 17.2167 16C17.1167 15.8 16.2834 13.7667 15.95 12.9334C15.6167 12.1334 15.2667 12.2334 15.0167 12.2167H14.2167C13.9334 12.2167 13.5 12.3167 13.1167 12.7334C12.75 13.15 11.6834 14.15 11.6834 16.1834C11.6834 18.2167 13.1667 20.1834 13.3667 20.45C13.5667 20.7334 16.2834 24.9 20.4167 26.6833C21.4 27.1167 22.1667 27.3667 22.7667 27.55C23.75 27.8667 24.65 27.8167 25.3667 27.7167C26.1667 27.6 27.8167 26.7167 28.15 25.75C28.5 24.7833 28.5 23.9667 28.3834 23.7833C28.2667 23.6 28.0167 23.5167 27.6 23.3167Z" fill="black"/>
                        </svg>
                    </button>
                </div>
            </div>
        );
    }
}

export default AdventureCard;